const URL = "http://localhost/DACN1_API/api";

export default {
    URL_getProduct: URL + "/getProduct.php",
    URL_getCategory: URL + "/getCategory.php",
    URL_getProductCategory: URL + "/getProductCategory.php",
    URL_getProductDetail: URL + "/getProductDetail.php",
    URL_getProductRelated: URL + "/getProductRelated.php",
    URL_searchProduct: URL + "/searchProduct.php",
    URL_getInformation: URL + "/getInformation.php",
    URL_getProductCategory: URL + "/getProductCategory.php",
    URL_updateCart: URL + "/updateCart.php",
    URL_deleteCart: URL + "/deleteCart.php",
    URL_getCart: URL + "/getCart.php",
    URL_setCart: URL + "/setCart.php",
    URL_setOrder: URL + "/setOrder.php",
    URL_setNewPass: URL + "/setNewPass.php",
    URL_setNewUser: URL + "/setNewUser.php",
    URL_setViewProduct: URL + "/setViewProduct.php",
    URL_getUser: URL + "/getUser.php",
    URL_getDetailOrder: URL + "/getDetailOrder.php",
    URL_getClientOrder: URL + "/getClientOrder.php",
    URL_getOrderByUser: URL + "/getOrderByUser.php",
    URL_getHomeAdmin: URL + "/getHomeAdmin.php",
    URL_getOrder: URL + "/getOrder.php",
    URL_getIdAdmin: URL + "/getIdAdmin.php",
    URL_getUserAdmin: URL + "/getUserAdmin.php",
    URL_deleteUser: URL + "/deleteUser.php",
    URL_editRole: URL + "/editRole.php",
    URL_addNewProduct: URL + "/addNewProduct.php",
    URL_deleteProduct: URL + "/deleteProduct.php",
    URL_deleteOrder: URL + "/deleteOrder.php",
    URL_editStatus: URL + "/editStatus.php",
    URL_addNewCategory: URL + "/addNewCategory.php",
    URL_deleteCategory: URL + "/deleteCategory.php",
    URL_editCategory: URL + "/editCategory.php",
    URL_getCategoryEdit: URL + "/getCategoryEdit.php",
    URL_editProduct: URL + "/editProduct.php",
    URL_getTopProduct: URL + "/getTopProduct.php",
    URL_getNewProduct: URL + "/getNewProduct.php",
    URL_setInfor: URL + "/setInfor.php",

    // setInfor.php
};
